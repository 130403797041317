import { getUnixTime } from "src/utils/date-time";
import { _mock } from "./_mock";
import { _regions } from "./_regions";

export const _devices = [...Array(12)].map((_, index) => {
  const deviceName = `SM-S${_mock.number.nativeL(index)}E`

  return {
  id: _mock.id(index),
  name: deviceName,
  region: _regions[index],
  ownerId: _mock.number.nativeS(index),
  enabled: _mock.boolean(index),
  visible: _mock.boolean(index + 1),
  model: _mock.macAddress(index),
  macAddress: _mock.macAddress(index),
  createdAt: getUnixTime(_mock.time(index)),
}});
