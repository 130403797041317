import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={name} />;

const ICONS = {
  map: icon('ic:outline-map'),
  device: icon('carbon:iot-platform'),
  region: icon('mdi:shape-polygon-plus'),
  home: icon('carbon:analytics'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          { title: t('dashboard'), path: paths.dashboard.root, icon: ICONS.home },
          { title: t('liveMap'), path: paths.dashboard.livemap, icon: ICONS.map },
          { title: t('devices'), path: paths.dashboard.devices, icon: ICONS.device },
          { title: t('regions'), path: paths.dashboard.regions, icon: ICONS.region },
        ],
      },
    ],
    [t]
  );

  return data;
}
