import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IUser } from 'src/types/user';

// ----------------------------------------------------------------------

export type TUserStore = {
  user?: IUser;
  setUser: (user: IUser) => void;
  resetUser: () => void;
};

const initializeState = {
  user: undefined,
};

export const useUserStore = create<TUserStore>()(
  devtools(
    immer((set) => ({
      ...initializeState,

      // Set user
      setUser: (user) => {
        set((state) => {
          state.user = user;
        });
      },

      // Reset user
      resetUser: () => {
        set((state) => {
          state.user = undefined;
        });
      },
    })),
    { name: 'UserStore', options: { serialize: true } }
  )
);
