// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// locales
import { LocalizationProvider } from 'src/locales';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
// auth
// import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';

// ----------------------------------------------------------------------
const charAt = `
NNNNNNNN        NNNNNNNN            d::::::d  iiii          tttt            iiii                                          333333333333333   DDDDDDDDDDDDD
N:::::::N       N::::::N            d::::::d i::::i      ttt:::t           i::::i                                        3:::::::::::::::33 D::::::::::::DDD
N::::::::N      N::::::N            d::::::d  iiii       t:::::t            iiii                                         3::::::33333::::::3D:::::::::::::::DD
N:::::::::N     N::::::N            d:::::d              t:::::t                                                         3333333     3:::::3DDD:::::DDDDD:::::D
N::::::::::N    N::::::N    ddddddddd:::::d iiiiiiittttttt:::::ttttttt    iiiiiiivvvvvvv           vvvvvvv eeeeeeeeeeee              3:::::3  D:::::D    D:::::D
N:::::::::::N   N::::::N  dd::::::::::::::d i:::::it:::::::::::::::::t    i:::::i v:::::v         v:::::vee::::::::::::ee            3:::::3  D:::::D     D:::::D
N:::::::N::::N  N::::::N d::::::::::::::::d  i::::it:::::::::::::::::t     i::::i  v:::::v       v:::::ve::::::eeeee:::::ee  33333333:::::3   D:::::D     D:::::D
N::::::N N::::N N::::::Nd:::::::ddddd:::::d  i::::itttttt:::::::tttttt     i::::i   v:::::v     v:::::ve::::::e     e:::::e  3:::::::::::3    D:::::D     D:::::D
N::::::N  N::::N:::::::Nd::::::d    d:::::d  i::::i      t:::::t           i::::i    v:::::v   v:::::v e:::::::eeeee::::::e  33333333:::::3   D:::::D     D:::::D
N::::::N   N:::::::::::Nd:::::d     d:::::d  i::::i      t:::::t           i::::i     v:::::v v:::::v  e:::::::::::::::::e           3:::::3  D:::::D     D:::::D
N::::::N    N::::::::::Nd:::::d     d:::::d  i::::i      t:::::t           i::::i      v:::::v:::::v   e::::::eeeeeeeeeee            3:::::3  D:::::D     D:::::D
N::::::N     N:::::::::Nd:::::d     d:::::d  i::::i      t:::::t    tttttt i::::i       v:::::::::v    e:::::::e                     3:::::3  D:::::D    D:::::D
N::::::N      N::::::::Nd::::::ddddd::::::ddi::::::i     t::::::tttt:::::ti::::::i       v:::::::v     e::::::::e        3333333     3:::::3DDD:::::DDDDD:::::D
N::::::N       N:::::::N d:::::::::::::::::di::::::i     tt::::::::::::::ti::::::i        v:::::v       e::::::::eeeeeeee3::::::33333::::::3D:::::::::::::::DD
N::::::N        N::::::N  d:::::::::ddd::::di::::::i       tt:::::::::::tti::::::i         v:::v         ee:::::::::::::e3:::::::::::::::33 D::::::::::::DDD
NNNNNNNN         NNNNNNN   ddddddddd   dddddiiiiiiii         ttttttttttt  iiiiiiii          vvv            eeeeeeeeeeeeee 333333333333333   DDDDDDDDDDDDD
`;
console.info(`%c${charAt}`, 'color: #5BE49B');
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  useScrollToTop();

  return (
    <LocalizationProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </QueryClientProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
