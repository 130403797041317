import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Home = lazy(() => import('src/pages/dashboard/home'));
const LiveMap = lazy(() => import('src/pages/dashboard/live-map'));
const LiveMapPage = lazy(() => import('src/pages/dashboard/live-map-page'));
const Devices = lazy(() => import('src/pages/dashboard/devices'));
const Regions = lazy(() => import('src/pages/dashboard/regions'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Home />, index: true },
      // { element: <LiveMap />, path: 'live-map' },
      { path: 'live-map', element: <LiveMapPage /> },
      { path: 'devices', element: <Devices /> },
      { path: 'regions', element: <Regions /> },
    ],
  },
];
