import { useMutation, useQuery } from '@tanstack/react-query';
import { first, isArray, isObject } from 'lodash';
import { useSnackbar } from 'notistack';
import { useLocales } from 'src/locales';
import { useAuthStore, useUserStore } from 'src/store';
import { ILoggedInUser, ILoginInfo, IRegisterInfo, IUser } from 'src/types/user';
import axiosInstance, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

export const AuthAPIKeys = {
  Login: 'LOGIN',
  ME: 'ME',
} as const;

// ----------------------------------------------------------------------

export function useMe() {
  const setUser = useUserStore((state) => state.setUser);

  const { data, isLoading, error } = useQuery({
    queryKey: [AuthAPIKeys.ME],
    async queryFn() {
      const URL = endpoints.auth.me;

      const res = await axiosInstance.get<IUser>(URL);

      setUser(res?.data);

      return res.data;
    },
  });

  return {
    user: data || [],
    userLoading: isLoading,
    userError: error,
  };
}

// ----------------------------------------------------------------------

export function useLogin() {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = useUserStore((state) => state.setUser);
  const setTokens = useAuthStore((state) => state.setTokens);

  const { mutateAsync, isLoading, error } = useMutation({
    async mutationFn(loginInfo: ILoginInfo) {
      const config = { _withToken: false };
      const { rememberMe, ...info } = loginInfo;

      const res = await axiosInstance.post<ILoggedInUser>(endpoints.auth.login, info, config);
      const { user, accessToken, refreshToken } = res?.data ?? {};

      setUser(user);
      setTokens(accessToken, refreshToken, rememberMe);

      return res.data;
    },
    onSuccess() {
      enqueueSnackbar(t('messages.welcome'));
    },
    onError(err: { detail?: string }) {
      enqueueSnackbar(err?.detail ?? t('errors.server'), { variant: 'error' });
    },
  });

  return {
    loginMutate: mutateAsync,
    loginDataLoading: isLoading,
    loginDataError: error,
  };
}

// ----------------------------------------------------------------------

export function useRegister() {
  const { t } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync, isLoading, error } = useMutation({
    async mutationFn(registerInfo: IRegisterInfo) {
      const config = { _withToken: false };

      const res = await axiosInstance.post<void>(endpoints.auth.register, registerInfo, config);

      enqueueSnackbar(t('messages.userRegistered'));

      return res.data;
    },
    onError(err: any) {
      let message;
      if (typeof err === "string") {
        message = err;
      } else if (err instanceof Error) {
        message = `[${err.name}]: ${err.message}`;
      } else if (err?.detail) {
        message = err?.detail;
      }

      if (message) {
        enqueueSnackbar(message, { variant: 'error' });
      } else if (isObject(err)) {

        Object.entries(err).forEach(([key, value]) => {
          if (Object.hasOwn(err, key)) {
            const msg = isArray(value) ? first(value) : value;
            enqueueSnackbar(`[${key}]: ${msg}`, { variant: 'error' });
          }
        })
      }
    },
  });

  return {
    registerMutate: mutateAsync,
    registerDataLoading: isLoading,
    registerDataError: error,
  };
}
