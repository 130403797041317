import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { useUserStore } from './user-store';

// ----------------------------------------------------------------------

export type TAuthStore = {
  token?: string;
  refreshToken?: string;
  rememberMe: boolean;
  setRememberMe: (rememberMe: boolean) => void;
  setToken: (token: string) => void;
  setTokens: (token: string, refreshToken: string, rememberMe: boolean) => void;
  setRefreshToken: (refreshToken: string) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
};

const initialState = {
  token: undefined,
  refreshToken: undefined,
  rememberMe: false,
};

export const useAuthStore = create<TAuthStore>()(
  devtools(
    persist(
      immer((set, get) => ({
        ...initialState,

        // Set remember me
        setRememberMe: (rememberMe) => {
          set((state) => {
            state.rememberMe = rememberMe;
          });
        },

        // Set token
        setToken: (token) => {
          set((state) => {
            state.token = token;
          });
        },

        // Set refresh token
        setRefreshToken(refreshToken) {
          set((state) => {
            state.refreshToken = refreshToken;
          });
        },

        // Set all tokens
        setTokens: (token, refreshToken, rememberMe) => {
          set((state) => {
            state.rememberMe = rememberMe;
            state.token = token;
            state.refreshToken = refreshToken;
          });
        },

        // Logout
        logout() {
          set((state) => {
            state.token = undefined;
            state.refreshToken = undefined;
            state.rememberMe = false;
          });
          useUserStore.getState().resetUser();
        },

        // Is authenticated
        isAuthenticated() {
          return !!get().token;
        },
      })),
      { name: 'auth' }
    ),
    { name: 'AuthStore', options: { serialize: true } }
  )
);
