// utils
import axiosInstance from './axios';
import { saveBlob } from './save-blob';

// ----------------------------------------------------------------------

type TDownload = {
  url: string;
  fileName: string;
  progress: number;
};
type TListener = () => void;

let downloads: TDownload[] = [];
let listeners: TListener[] = [];

function emitChange() {
  listeners.forEach((listener) => listener());
}

const DownloadManager = {
  download(url: string, fileName: string) {
    const index = downloads.length;
    downloads = [...downloads, { url, fileName, progress: 0 }];
    emitChange();
    axiosInstance
      .get(url, {
        _hasLoading: false,
        onDownloadProgress({ progress = 0 }) {
          downloads[index].progress = Math.floor(progress * 100);
          downloads = [...downloads];
          emitChange();
        },
        responseType: 'blob',
        timeout: Infinity,
      })
      .then((res) => {
        saveBlob(res.data, fileName);
      });
  },
  subscribe(listener: TListener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return downloads;
  },
};

export default DownloadManager;
