import { useEffect } from 'react';
// sentry
import { init, BrowserTracing, reactRouterV6Instrumentation, wrapUseRoutes } from '@sentry/react';
// react-router
import {
  useRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
// config
import { IS_PRODUCTION, SENTRY_DSN, MODE } from 'src/config-global';

// ----------------------------------------------------------------------

init({
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  environment: MODE,
  release: __APP_VERSION__,
  enabled: IS_PRODUCTION,
});

export const useSentryRoutes = wrapUseRoutes(useRoutes);
