import { useSyncExternalStore } from 'react';
// @mui
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
// motion
import { m } from 'framer-motion';
// components
import { varHover } from 'src/components/animate';
import Iconify from 'src/components/iconify';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import { usePopover } from 'src/components/custom-popover';
// utils
import DownloadManager from 'src/utils/downloads-manager';
// hooks
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

function DownloadsPopover() {
  const popover = usePopover();
  const { t } = useLocales();
  const downloads = useSyncExternalStore(DownloadManager.subscribe, DownloadManager.getSnapshot);
  const inProgressItems = downloads.filter((d) => d.progress !== 100);

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={popover.open ? 'primary' : 'default'}
        onClick={popover.onOpen}
      >
        <Badge badgeContent={inProgressItems.length} color="error">
          <Iconify icon="material-symbols:download" width={24} />
        </Badge>
      </IconButton>
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 200, p: 0 }}
        arrow="top-center"
      >
        <Box sx={{ p: 1.5, pb: 1 }}>
          <Typography variant="subtitle2" noWrap textAlign="center">
            {t('downloads')}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {!downloads.length && (
          <Typography component="div" textAlign="center" variant="caption" p={1} color="gray">
            {t('noDownloads')}
          </Typography>
        )}

        {downloads.map((download, index) => (
          <Box sx={{ m: 1.5 }} key={index}>
            <Stack direction="row" gap={1.5} alignItems="center">
              <Box
                width={40}
                height={40}
                p={0.7}
                borderRadius={9999}
                border="dashed"
                borderColor="lightgray"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Iconify icon="vscode-icons:file-type-excel" width={36} />
              </Box>
              <Stack>
                <Tooltip title={download.fileName}>
                  <Typography
                    variant="body2"
                    sx={{ textOverflow: 'ellipsis', maxWidth: 120 }}
                    noWrap
                  >
                    {download.fileName}
                  </Typography>
                </Tooltip>
                <Typography variant="caption">{download.progress}%</Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
      </CustomPopover>
    </>
  );
}

export default DownloadsPopover;
