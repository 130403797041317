import axios from 'axios';
import NProgress from 'nprogress';
// config
import { HOST_API } from 'src/config-global';
import { paths } from 'src/routes/paths';
import { useAuthStore } from 'src/store';
import { ILoggedInUser } from 'src/types/user';
import { isValidToken } from './auth-utils';
// utils
// import { isValidToken } from './auth-utils';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  _hasLoading: true,
  _withToken: true,
  _isRefreshingToken: false,
  timeout: 10000,
});

async function refreshCurrentToken(refreshToken: string) {
  const { data } = await axiosInstance.post<ILoggedInUser>(
    endpoints.auth.refreshToken,
    { refresh_token: refreshToken },
    {
      _isRefreshingToken: true,
    }
  );

  useAuthStore.getState().setTokens(data.accessToken, data.refreshToken, true);
  return data.accessToken;
}

// TODO: Improve loading by using onDownloadProgress and onUploadProgress
axiosInstance.interceptors.request.use(
  async (config) => {
    if (config._hasLoading) NProgress.start();
    if (config._withToken) {
      const { token = '', rememberMe = false, refreshToken = '' } = useAuthStore.getState();
      let newToken = token;

      if (!config._isRefreshingToken && rememberMe && !isValidToken(token)) {
        newToken = await refreshCurrentToken(refreshToken);
      }

      config.headers.Authorization = `${newToken}`;
    }

    return config;
  },
  (error) => {
    NProgress.done();
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    NProgress.done();
    if (error?.config?._withToken && [401].includes(error?.response?.status)) {
      useAuthStore.getState().logout();
      window.location.href = paths.auth.jwt.login;
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: 'v1/users/me',
    login: 'v1/tokens/',
    register: 'v1/users/',
    refreshToken: 'v1/tokens/refresh/',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  device: {
    list: 'v1/devices/',
    create: 'v1/devices/',
    update: 'v1/devices/:id/',
    delete: 'v1/devices/:id/',
  },
  metrics: {
    list: 'v1/metrics/',
    export: 'v1/metrics/',
  },
  region: {
    list: 'v1/regions/',
    create: 'v1/regions/',
    update: 'v1/regions/:id',
    delete: 'v1/regions/:id/',
  },
};
