import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
// components
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSettingsContext } from 'src/components/settings';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  showBrand?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, showBrand = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();
    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 180,
          // height: 40,
          display: 'inline-flex',
          alignItems: 'center',
          ...sx,
        }}
        {...other}
      >
        <LazyLoadImage
          alt="Logo"
          // height={40}
          // width={50}
          // src={`/logo/logo192-${settings.themeMode === 'light' ? 'dark' : 'light'}.png`}
          src={`/logo/Blue.png`}
        />
        {showBrand && <Typography variant="h5">nditive3D</Typography>}
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }} color="inherit">
        {logo}
      </Link>
    );
  }
);

export default Logo;
